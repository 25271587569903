import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/vercel/path0/frontend/packages/component/src/client-providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/vercel/path0/frontend/packages/component/src/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataDogAnalysis"] */ "/vercel/path0/frontend/src/analytics/DataDogAnalysis.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["UserSideEffect"] */ "/vercel/path0/frontend/src/components/user-side-effect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactSection"] */ "/vercel/path0/frontend/src/layouts/sidebar-layout/navigation/contact-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayoutProvider"] */ "/vercel/path0/frontend/src/layouts/sidebar-layout/shared.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginProvider","providerMap","DEFAULT_LOGIN_PROVIDER","GOOGLE"] */ "/vercel/path0/frontend/src/lib/firebase/auth.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentProjectIdProviderInternal"] */ "/vercel/path0/frontend/src/store/current-project-id/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/frontend/src/store/user/client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+t_912cb03e86e156b532a036c1afc63387/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+t_912cb03e86e156b532a036c1afc63387/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+t_912cb03e86e156b532a036c1afc63387/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+test_29af2ce5885df905a5a5b620a1ebb69b/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright_d06fe6a5c7959e3c3a9f1b776a68db74/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/driver.js@1.3.1/node_modules/driver.js/dist/driver.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/filepond@4.31.4/node_modules/filepond/dist/filepond.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.4_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+_35b67708804da0b76dcf64686138eb74/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.50_ae65ed8e725c39a116eafe216c1cc807/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.50_ae65ed8e725c39a116eafe216c1cc807/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
